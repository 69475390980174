/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from "../components/seo";

class Coupon extends React.Component {
  componentDidMount() {
    let incoming = sessionStorage.getItem('incomingsource');

    if (incoming && incoming.charAt(0) === '?') {
      incoming = incoming.substr(1);
    }

    // Add revtrax coupon
    const script = document.createElement("script");

    script.src = "https://irxcm.com/RevTrax/js/rtxiframe.jsp?parent=couponiframe&rtxuseqs=true&merchantId=30828557&programId=107772976&affiliateId=31760427&channel=brand&rx_channel=brand"
    if (incoming) {
      script.src = script.src + '&' + incoming
    }

    script.async = true;

    script.addEventListener('load', () => {
      // Add revtrax render function
      const script2 = document.createElement("script");

      script2.type = 'text/javascript';
      script2.innerHTML = 'makeFrame();';
      script2.title = 'Coupon';

      document.body.appendChild(script2);
    });

    document.body.appendChild(script);
  }

  render() {

    return (
      <Layout>
        <SEO meta={{}} title="Coupon">
          <meta name="robots" content="noindex" />
        </SEO>
        <h1 className="sr-only">GG Coupon</h1>
        <main id="couponiframe" />
      </Layout>
  )
  }
}
export default Coupon;
